.footer-lnk-cols {
	display: block;
}

.mobile-footer-lnk-cols {
	display: none;
}

.email-desktop {
	display: block;
}

.email-mobile {
	display: none;
}

.footer-wrapper {
	display: flex;
}

#mobile-menu {
	display: none;
	width: 100%;
	text-align: left;
}

#mobile-menu .fa-bars {
	top: 0px;
	left: -10px;
	position: relative;
}

#mobile-menu ul {
	list-style: none;
}

#mobileNav {
	height: 0px;
	visibility: hidden;
	opacity: 0;
	width: 100%;
	margin: 0 auto;
	background-color: #fff;
}

#mobileNav {
	visibility: hidden;
	opacity: 0;
	width: 100%;
	margin: 0 auto;
}

#mobileNav ul {
	width: 100%;
	margin: 0 auto;
	height: 0px;
	visibility: hidden;
	opacity: 0;
	background: #fff !important;
}

#mobileNav li {
	border-bottom: 1px solid #e9e9e9;
	width: 100%;
	font-size: 16px;
	text-align: left;
	padding: 20px 0px 20px 0px;
	background-color: #fff;
}

#mobileNav ul li {
	width: 100%;
	border-bottom: none;
}

#mobileNav ul li a {
	color: #808080;
	font-size: 14px;
	padding-left: 44px;
}

#mobileNav li a {
	color: #000000;
	margin-left: 20%;
}

.activemenu {
	background: #000 !important;
	padding-bottom: 0px !important;
}

.activelink {
	color: #c5a44b !important;
	text-decoration: none !important;
}

.menu-icon-button {
	background: #c7a652;
	width: 100%;
	display: block;
	padding: 15px 10px 15px 10px;
	color: #fff;
}

.menu-icon-button:hover {
	color: #fff;
	text-decoration: none;
}

#mobile-service-slider,
#mobile-service-controls,
.mobile-members-slider {
	display: none;
}

@media only screen and (max-width: 1366px) {
	.text {
		padding-left: 52px;
	}
	#content-middle > div.inner,
	#content > div.content-columns {
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}
	#content-cash > .cash-row {
		height: 480px;
		margin-right: 20px;
		width: 320px;
	}
	#team-content {
		padding-bottom: 0px;
	}
	#team-content > div > div.members-slider {
		margin-bottom: 100px;
		padding-right: 5%;
	}
	#footer-links > div {
		width: 95%;
	}
	#footer-links .left-column {
		width: 85%;
	}
	#footer-links .right-column {
		width: 35%;
	}
	.member-info {
		padding: 25px 16px 60px 16px;
	}
	#bottom-content {
		height: 450px;
	}
	#bottom-content .row-container h2 {
		font-size: 30px;
	}
	#bottom-content .row-container p {
		font-size: 16px;
	}
	#space-white {
		padding-bottom: 370px;
	}
	header .header-wrapper,
	#banner-info {
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}
	#frm-contact {
		margin-left: 0;
		margin-right: 0;
		float: none;
		width: 100%;
	}
	#video-section {
		margin-top: -1px;
	}
	.row-container {
		width: 650px;
	}
	#content-contact .inner-big {
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}
	.members-slider {
		width: 1230px;
	}
	h2.montSemiBold,
	h3.prata2 {
		line-height: 1.4em;
	}
	.service-container p.small,
	.right-contact-row p.small,
	.bottom-row-left .row-container p.small {
		line-height: 2em;
	}
	.row-container {
		margin-top: 40px;
	}
	#content-cash {
		margin-top: 40px;
	}
	.content-scroll {
		height: 560px;
	}
	#quote-content-container {
		width: 95%;
	}
	#quote-content {
		background-position: -moz-calc(50% - (567px/2.75)) 41px, left top,
			left bottom, right bottom;
		background-position: -webikit-calc(50% - (567px/2.75)) 41px, left top,
			left bottom, right bottom;
		background-position: calc(50% - (567px / 2.75)) 41px, left top, left bottom,
			right bottom;
	}
	#quote-content-left > img {
		height: 192px;
	}
	#left-content-text-wrapper {
		padding-bottom: 8px;
	}
	#quote-content-right > .frm-contact h5.checkboxHeaderText,
	#quote-content-right > .frm-contact .button-controls-container,
	#quote-content-right > .frm-contact .comment-container,
	#quote-content-right > .frm-contact .input-container-select,
	#quote-content-right > .frm-contact label,
	#quote-content-right > .frm-contact .input-container,
	#quote-content-right > .frm-contact .chkbox-container {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
	#quote-content-right .checkbox-wrapper-1,
	#quote-content-right .checkbox-wrapper-2,
	#quote-content-right .checkbox-wrapper-3 {
		width: 150px;
	}
	#quote-content-right .checkbox-wrapper-4,
	#quote-content-right .checkbox-wrapper-5,
	#quote-content-right .checkbox-wrapper-6 {
		width: 134px;
	}
	#quote-content-right .checkbox-wrapper-7,
	#quote-content-right .checkbox-wrapper-8,
	#quote-content-right .checkbox-wrapper-9 {
		width: 118px;
	}
	#quote-content-right > .frm-contact label {
		margin-left: 0;
		margin-right: 0;
	}
	#quote-content-right .chkbox-container-cash .checkbox-wrapper-6 {
		justify-self: center;
	}
	#quote-content-right > .frm-contact .long-lbl {
		width: 90% !important;
	}
	#banner-info .email-desktop {
		display: none;
	}
	#banner-info .email-mobile {
		display: block;
	}
	#about-us-team-slider .inner,
	.section-col,
	#headline-about-us {
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}
	#about-us-team-slider > .inner .team-carousel-wrapper {
		width: -moz-calc(100% - 56px);
		width: -webikit-calc(100% - 56px);
		width: calc(100% - 56px);
	}
	#about-team-scroll-controll {
		right: -moz-calc((100% - 95%) - 2%);
		right: -webikit-calc((100% - 95%) - 2%);
		right: calc((100% - 95%) - 2%);
	}
	.team-item-wrapper {
		min-width: 45%;
		width: 45%;
		margin-left: 2.5%;
		margin-right: 2.5%;
	}
	#contact-testimonial-section .inner,
	#contact-form-section .inner {
		width: 95%;
	}
	#welcome-header-section {
		min-height: auto;
		height: 60vh;
	}
	#welcome-header-text-wrapper {
		margin-bottom: 80px;
	}
	#welcome-header-section .inner {
		width: 95%;
	}
	#black-box .content {
		max-width: calc(100% - 5%);
	}
	#instruction-box {
		margin-right: calc((1366px - (1366px * 0.95)) / 2);
	}
	#header-image-wrapper {
		width: 850px;
	}
	#faq,
	#guarantee,
	#pitch {
		width: 95%;
	}
}

/* 1366px */
@media only screen and (max-width: 1200px) {
	#space-white h5 {
		width: 300px;
		right: 20px;
		position: absolute;
		text-align: center;
		display: none;
	}
	.members-slider {
		width: 1020px;
		padding-right: 0px;
	}
	.row-container {
		width: 540px;
	}
	#content-cash {
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-20%);
	}
	.slides {
		height: 385px;
	}
	#quote-content-right > #frm-contact {
		width: 95%;
		height: 910px;
		margin-left: auto;
	}
	.chkbox-grid {
		grid-template-rows: repeat(4, 1fr);
	}
	.checkbox-wrapper-4 {
		justify-self: start;
	}
	.checkbox-wrapper-5,
	.checkbox-wrapper-6,
	.checkbox-wrapper-7,
	.checkbox-wrapper-8,
	.checkbox-wrapper-9 {
		justify-self: end;
		width: 80%;
	}
	#quote-content-right .checkbox-wrapper-7,
	#quote-content-right .checkbox-wrapper-8,
	#quote-content-right .checkbox-wrapper-9 {
		width: 132px;
	}
	#quote-content-right > .frm-contact .chkbox-row {
		margin-bottom: 20px;
	}
	#quote-content-right .chkbox-container-cash .checkbox-wrapper-6 {
		justify-self: end;
	}
	#quote-content-right > .frm-contact input {
		padding: 16px;
	}
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-3 {
		width: 152px;
	}
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-5 {
		justify-self: start;
	}
	#frm-contact .chkbox-container-cash,
	#quote-content-right > #frm-contact .chkbox-container.chkbox-container-cash {
		grid-template-rows: repeat(5, 1fr);
	}
	#frm-contact .chkbox-container-cash .checkbox-wrapper-5 {
		justify-self: start;
	}
	#quote-content-right > .frm-contact label {
		margin-left: 0;
		margin-right: 0;
	}
	#frm-contact {
		height: 900px;
	}
	.content-scroll {
		height: 642px;
	}
	.section-content-wrapper img {
		width: 100%;
	}
	.team-item-about-content p {
		width: -moz-calc(100% - 190px);
		width: -webikit-calc(100% - 190px);
		width: calc(100% - 190px);
	}
	#about-us-team-slider .team-carousel-wrapper .team-carousel .item-container {
		width: 230px;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.item-container
		.member-info {
		padding: 16px;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.item-container
		.member-info
		p,
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.item-container
		.member-info
		a {
		font-size: 12px;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.team-item-about-content {
		height: -moz-calc(100% - 100px);
		height: -webikit-calc(100% - 100px);
		height: calc(100% - 100px);
		justify-content: flex-end;
		align-items: center;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.team-item-about-content
		.wrapper {
		height: 80%;
		overflow: scroll;
		align-self: center;
		width: 50%;
		padding-left: 16px;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.team-item-about-content
		.wrapper
		p {
		margin-left: 0px;
		width: 100%;
	}

	//Add

	#pitch {
		.col-right {
			.cta-box {
				img {
					height: 100%;
				}
			}
		}
	}
	// End Add
}

/* @media only screen and (max-width: 1024px) */
@media only screen and (max-width: 1024px) {
	#team-content {
		padding-bottom: 0px;
	}
	#space-white h5 {
		display: none;
	}
	#content-middle {
		padding-top: 0;
	}
	#content-middle div.left-row h3.prata2 {
		font-size: 28px !important;
		line-height: 1em;
	}
	.left-row-content-middle h3.montSemiBold {
		font-size: 30px !important;
		line-height: 1.5em;
		padding: 16px 0;
	}
	#content-middle > div.inner > div.left-row > div > h3.small {
		font-size: 12px;
		line-height: 1.3em;
		margin-bottom: 0;
	}
	.text-paragraph {
		padding-right: 30px;
	}
	#content-contact > div > div.right-contact-row > div {
		height: 565px;
	}
	#content-contact > div > div.right-contact-row {
		padding-top: 0;
	}
	#content-contact > div > div.right-contact-row > h1 {
		padding-right: 16px;
	}
	#content-contact > div > div.right-contact-row > h5 {
		margin-bottom: 20px;
	}
	.item-container {
		width: 290px;
	}
	#space-white {
		padding-bottom: 430px;
	}
	.video {
		width: 100%;
	}
	#step-dots-container {
		margin-right: 0;
		width: 100%;
	}
	#main-menu ul {
		width: 97%;
	}
	#banner .text hr,
	.row-container .divider-2 {
		margin-bottom: 18px;
	}
	#banner-info {
		width: 95%;
		display: inline-block;
		border-radius: 0;
		margin: 0 auto;
		width: 100%;
		top: 0;
		padding: 4px 0;
	}
	#banner-info .mid-row {
		width: 100%;
		margin: 0 auto;
		float: none;
		padding-top: 4px;
		padding-bottom: 4px;
	}
	.content-columns .leftside-column {
		padding-left: 0;
	}
	div.chkbox-container.chkbox-row input,
	input[type='checkbox'] + label span {
		width: 20px;
		height: 20px;
		padding: 0;
		margin: 0;
		margin-right: 5px;
	}
	div.chkbox-container.chkbox-row input:checked + label:after {
		left: 6px;
		width: 4px;
		height: 12px;
	}
	.frm-contact .control-wrapper .chkbox label,
	.chkbox-container h5,
	.chkbox-container p {
		font-size: 14px !important;
		display: inline;
	}
	.chkbox-container p {
		width: 150px;
	}
	.chkbox-container .control-wrapper div:nth-child(1) {
		margin-right: 16px;
	}
	.frm-contact {
		height: 912px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.email-desktop {
		display: none;
	}
	.email-mobile {
		display: block;
	}
	#footer-links .left-column {
		width: 65%;
	}
	#footer-links .right-column {
		width: 40%;
	}
	.members-slider {
		width: 890px;
		padding-right: 0px !important;
	}
	.text {
		top: 20px;
		padding-left: 25px;
	}
	.inner-controls {
		right: -15px;
	}
	.main-content-scroll {
		height: 290px;
	}
	.slides {
		height: 340px;
	}
	.row-container {
		width: 490px;
		margin-top: 32px;
	}
	#content-cash {
		margin-top: 32px;
		width: 900px;
		left: 78px;
		transform: none;
	}
	#content-cash > .cash-row {
		width: 30%;
		height: 495px;
		padding: 0 16px 16px 16px;
	}
	h2.montSemiBold,
	h3.prata2 {
		line-height: 1.4em;
	}
	.service-container p.small,
	.right-contact-row p.small,
	.bottom-row-left .row-container p.small {
		line-height: 2em;
	}
	.left-row-content-middle {
		padding-right: 40px;
	}
	.right-row-content-middle {
		/* flex:4;
        margin-left: 0; */
	}
	.service-info .text-heading,
	.service-info .text-paragraph {
		line-height: 1.6em;
	}
	.text {
		padding-top: 0;
		padding-bottom: 0;
		bottom: auto;
		/* background-color: violet; */
		top: 55px;
	}
	#banner .text hr {
		margin-top: 0;
		margin-left: auto;
		margin-right: auto;
	}
	.frm-contact h3,
	.frm-contact h4,
	.frm-contact h5,
	.frm-contact p {
		line-height: 1.6em;
	}
	#content-contact > div > div.right-contact-row > div {
		height: 658px;
	}
	#bottom-content {
		height: 390px;
	}
	.bottom-row-right {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	.text {
		width: 100%;
	}
	.text h3,
	#banner .text h1,
	.text p {
		text-align: center;
	}
	div.btn-learn-more {
		margin-left: auto;
		margin-right: auto;
	}
	#headline-content {
		height: 300px;
	}
	#quote-content-left > img {
		height: 234px;
	}
	#quote-content {
		background-size: auto 209px, 100% 250px, 50%, 50%;
		background-position: -moz-calc(50% - (567px/2.5)) 41px, left top,
			left bottom, right bottom;
		background-position: -webikit-calc(50% - (567px/2.5)) 41px, left top,
			left bottom, right bottom;
		background-position: calc(50% - (567px / 2.5)) 41px, left top, left bottom,
			right bottom;
	}
	#left-content-text-wrapper {
		padding-left: 0px;
		margin-top: 250px;
	}
	#headline-wrapper h1 {
		font-size: 40px;
	}
	#headline-wrapper h3.prata {
		font-size: 24px;
	}
	#headline-wrapper p {
		font-size: 16px;
	}
	#headline-wrapper h1,
	#headline-wrapper h3.prata,
	#headline-wrapper p,
	#headline-wrapper hr {
		margin-bottom: 16px;
	}
	#quote-content-left h3 {
		font-size: 32px;
	}
	.bg-dots {
		background-size: contain;
	}
	#quote-content-left {
		flex: 1;
	}
	.checkbox-wrapper-4 {
		justify-self: left;
	}
	.checkbox-wrapper-5,
	.checkbox-wrapper-6,
	.checkbox-wrapper-7,
	.checkbox-wrapper-8 {
		justify-self: right;
		width: 120px;
	}
	#quote-content-right > #frm-contact {
		height: 906px;
	}
	#frm-contact > div.tab.tab-default.t-included.tab-show > h3 {
		margin-top: 16px;
	}
	#quote-content-right > #frm-contact .chkbox-grid {
		grid-template-rows: repeat(3, 1fr);
	}
	.chkbox-grid {
		grid-template-rows: repeat(4, 1fr);
	}
	.chkbox-grid.chkbox-container-cash {
		grid-template-rows: repeat(5, 1fr);
	}
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-5 {
		justify-self: start;
	}
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-6,
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-7,
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-8,
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-9 {
		width: 100px;
	}
	#quote-content-right > .frm-contact input {
		padding: 8px 12px;
	}
	#frm-contact > div.tab.tab-default.t-included.tab-show > h3 {
		margin-top: 0;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}
	#quote-content-right
		> #frm-contact
		> div.tab.tab-change.t-included.tab-show
		> div.chkbox-container.chkbox-grid
		.checkbox-wrapper-5 {
		justify-self: center;
	}
	#quote-content-right .checkbox-wrapper-7,
	#quote-content-right .checkbox-wrapper-8,
	#quote-content-right .checkbox-wrapper-9 {
		width: 134px;
	}
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-9 {
		width: 110px;
	}
	#quote-content-right > #frm-contact .chkbox-container.chkbox-container-cash {
		grid-template-rows: repeat(4, 1fr);
	}
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-4,
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-6,
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-7,
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-8 {
		justify-self: start;
	}
	#headline-about-us .content {
		width: 50%;
		padding-bottom: 0;
	}

	#about-us-section-help .content,
	#about-us-section-service .content {
		max-height: 100%;
		height: -moz-calc(100% - 136px);
		height: -webikit-calc(100% - 136px);
		height: calc(100% - 136px);
	}
	#about-us-section-story .section-header {
		left: 0;
		right: auto;
	}
	#about-us-section-story .content {
		height: -moz-calc(100% - 136px);
		height: -webikit-calc(100% - 136px);
		height: calc(100% - 136px);
		max-height: 100%;
	}
	.about-us-section .section-col {
		position: relative;
		height: -moz-calc(342px + 40px);
		height: -webikit-calc(342px + 40px);
		height: calc(342px + 40px);
	}
	/* #about-us-section-service */
	#about-us-section-story,
	#about-us-section-service,
	#about-us-section-help {
		/* position: relative; */
	}
	.about-us-section .section-content {
		padding: 32px 32px 0px 32px;
	}
	.about-us-section .section-header {
		position: absolute;
		top: 0;
		right: 0;
		width: auto;
		padding: 40px 32px;
	}
	.about-us-section .section-header h3 {
		padding: 0;
		margin: 0;
	}
	.about-us-section .section-header hr {
		display: none;
	}
	.section-content-wrapper img {
		height: -moz-calc(100% - (136px / 2));
		height: -webikit-calc(100% - (136px / 2));
		height: calc(100% - (136px / 2));
		margin-top: -moz-calc(136px / 2);
		margin-top: -webikit-calc(136px / 2);
		margin-top: calc(136px / 2);
	}
	#about-us-section-story .about-us-section .section-header {
		left: 0;
	}
	#about-us-section-help {
		/* height: 500px; */
	}
	#about-us-section-help .section-col {
		height: 500px;
	}
	#about-us-section-service .section-col {
		height: auto;
	}
	#about-us-banner > #banner-mid-content::before {
		background-position: center;
	}
	.bg-dots {
		background-size: 50%, 50%;
	}
	#testimonial-carousel .item .content .wrapper .excerpt {
		height: 112px;
		overflow: scroll;
	}
	#testimonial-carousel .scroll-control {
		height: 40px;
	}
	#contact-form-content-wrapper {
		flex-direction: column;
	}
	#contact-form-info-panel {
		width: 100%;
		border-bottom-left-radius: 0px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		padding-left: 10%;
		padding-right: 10%;
	}
	#contact-form-info-panel .content hr {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 56px;
	}
	#contact-form-info-panel .content {
		padding-top: 49px;
		width: auto;
		position: relative;
		top: auto;
	}
	#contact-form-form-panel {
		width: 100%;
	}
	#contact-form-form-panel .content .callback-button {
		margin-left: auto;
		margin-right: auto;
	}
	#contact-form-form-panel .content {
		width: 100%;
		margin: 56px;
	}
	#contact-form-info-panel .content .info-wrapper {
		justify-content: center;
	}
	#hero-image {
		min-height: 40vh;
	}
	#hero-image .wrapper p {
		margin-bottom: 0;
	}
	#header-image-wrapper {
		width: 613px;
		height: 100%;
	}
	#welcome-header-text-wrapper {
		width: 384px;
		margin-bottom: 0px;
	}
	#welcome-header-section {
		height: 435px;
	}
	#welcome-header-text-wrapper h1 {
		font-size: 64px;
	}
	#welcome-header-text-wrapper h3.prata2 {
		font-size: 40px !important;
	}
	#welcome-header-text-wrapper p {
		min-height: 160px;
		max-height: 160px;
		overflow: auto;
		padding: 24px;
	}
	#instruction-box {
		max-width: calc((1024px / 2) + 50px);
	}
	#instruction-box .content {
		padding: 32px;
	}
	#black-box .content {
		padding-right: 118px;
	}

	// Add

	#pitch {
		.col-right {
			.cta-box {
				img {
					height: 101%;
					object-fit: cover;
				}
			}
		}
	}
	#guarantee {
		.col-right {
			.cta-box {
				img {
					height: 740px;
					object-fit: cover;
					object-position: 30%;
				}
			}
		}
	}
	// End Add
}

/* 1024 */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
	#hero-image {
		min-height: 60vh;
	}
}

@media only screen and (max-width: 992px) {
	#hero-image {
		margin-top: 40px;
		background-position: 50%;
	}
	#hero-image h1 {
		font-size: 40px;
		line-height: 48px;
	}
	#pitch .cta-overlay {
		width: 60%; // Add/Edit width: 70%;
	}
	#faq h3.header-text,
	#guarantee h3.header-text,
	#pitch h3.header-text {
		font-size: 28px;
		line-height: 43px;
	}
	.spacer-lg {
		height: 80px;
	}
	header {
		height: auto;
		position: relative;
		z-index: 1;
	}
	header .inner {
		padding-bottom: 0px;
		flex-direction: column;
	}
	header .header-wrapper {
		flex-direction: column;
	}
	/* #wrapper, .content-wrapper{ */
	.content-wrapper {
		padding-top: 0px;
		margin-top: -3px;
	}
	/* #wrapper: {

    } */
	.email-desktop {
		display: none;
	}
	.email-mobile {
		display: block;
	}
	#main-menu,
	.text .divider,
	.service-container .divider,
	#bottom-content .bottom-row-right,
	#space-white {
		display: none;
	}
	#mobile-menu {
		display: block;
	}
	#mobile-menu a:hover {
		text-decoration: none;
	}
	.inner,
	.inner-big {
		width: 100%;
		margin: 0 auto;
	}
	#logo {
		margin: 0 auto;
		text-align: center;
		width: 100%;
		padding-bottom: 20px;
	}
	#logo a img {
		height: auto;
	}
	.menu-icon-button {
		padding-left: 30px;
	}
	.inner-controls {
		margin-right: 0px !important;
	}
	.active,
	.dot:hover {
		height: 15px !important;
		width: 15px !important;
	}
	.service-container {
		padding-top: 0;
	}
	#content {
		width: 100%;
	}
	.main-content-scroll {
		height: auto;
		width: 82%;
		padding: 0px;
		margin: 0 auto;
	}
	#content .center-content {
		padding-bottom: 0px !important;
	}
	.content-columns,
	#content .center-content {
		display: block;
	}
	.content-columns {
		padding-top: 0px;
	}
	.content-columns .leftside-column p {
		margin-bottom: 16px;
	}
	#content .center-content {
		width: 82%;
		margin: 0 auto;
	}
	#content p {
		text-align: justify;
	}
	.content-columns .rightside-column,
	.content-columns .leftside-column {
		float: none;
		width: 100% !important;
		margin: 0 auto;
		padding: 0px;
	}
	.content-columns .leftside-column h3 {
		text-align: center !important;
	}
	.content-columns .rightside-column {
		padding-bottom: 50px;
	}
	.content-columns .rightside-column iframe {
		width: 100%;
		height: calc(100vw / 1.7);
	}
	#content-middle {
		min-height: 540px;
		padding: 0;
	}
	#content-middle .right-row {
		padding-left: 0px;
		display: flex;
	}
	#service-slider {
		width: 80%;
		margin: 16px auto;
	}
	#banner img {
		min-height: 300px;
	}
	#banner-mid {
		background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.5),
				rgba(0, 0, 0, 0.5)
			),
			url('../images/banner/home-statistics-mobile.jpg') !important;
		background-size: cover;
		background-repeat: no-repeat;
		height: 920px;
	}

	.no-webp #banner-mid {
		background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.5),
				rgba(0, 0, 0, 0.5)
			),
			url('../images/banner/home-statistics-mobile.jpg') !important;
		background-size: cover;
		background-repeat: no-repeat;
		height: 920px;
	}

	/*aaaaaa/ */
	#banner-mid-content {
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		background-position: 50% 50%;
	}
	.bottom-center-content h4 {
		font-size: 40px;
	}
	.bottom-center-content {
		position: relative;
		left: 0%;
		right: 0%;
		padding: 0 20px 0 20px;
		margin: 0 auto;
	}
	.vl {
		border-top: 2px solid #fff;
		border-bottom: 2px solid #fff;
		border-left: none !important;
		border-right: none !important;
		height: 250px;
		position: absolute;
		width: 25%;
		left: 37%;
		margin-top: -42px;
	}
	#banner-mid .mid-row {
		width: 100%;
		float: none;
		margin: 0 auto;
		padding-bottom: 50px;
	}
	#banner-info {
		border-radius: 0;
		margin: 0 auto;
		width: 100%;
		top: 0;
		padding: 10px 0 20px 0;
	}
	#banner-info .mid-row {
		width: 100%;
		margin: 0 auto;
		float: none;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.text {
		text-align: center !important;
		width: 100% !important;
		left: 50%;
		top: 20%;
		transform: translate(-50%, 0);
	}
	.text h3 {
		font-size: 24px !important;
		width: 90% !important;
		margin: 0 auto 24px auto;
	}
	.text h1 {
		font-size: 30px !important;
		width: 90% !important;
		margin: 0 auto 24px auto;
	}
	.text p {
		font-size: 16px !important;
		width: 86% !important;
		margin: 0 auto;
		line-height: 1em;
	}
	.btn-learn {
		margin-top: 20px;
		padding: 15px 20px 15px 20px !important;
		font-size: 18px !important;
	}
	.dot-navigation {
		top: 32% !important;
	}
	.left-row {
		width: 100%;
		margin: 0 auto;
		float: none;
		text-align: center;
	}
	.right-row {
		width: 100%;
		margin: 0 auto;
		float: none;
	}
	.text-heading {
		font-size: 16px;
	}
	#mobile-service-slider img {
		width: 75px;
		height: 75px;
	}
	.video {
		width: 100%;
		display: inline-block;
		clear: both;
		top: 0px;
		height: auto;
		background-color: #fff;
	}
	.video iframe {
		position: relative;
		width: 100%;
		height: calc(100vh / 1.7);
	}
	.video h5 {
		top: 0;
		z-index: 0;
		font-size: 16px;
		padding: 10px 0;
		padding-bottom: 18px;
	}
	.text-paragraph {
		padding-right: 0px !important;
		width: 70%;
		text-align: center !important;
		font-size: 14px;
		line-height: 1.5em;
	}
	#content-contact .inner-big {
		display: block;
	}
	.left-contact-row {
		float: none !important;
		width: 100% !important;
		margin: 0 auto !important;
	}
	.right-contact-row {
		float: none !important;
		width: 96% !important;
		margin: 0 auto !important;
		padding-top: 70px !important;
	}
	.right-contact-row .divider {
		margin: 20px auto 25px auto !important;
	}
	.right-contact-row ul {
		width: 100% !important;
	}
	.right-contact-row h5 {
		padding: 0 10px 0 10px;
	}
	.right-contact-row h4 {
		margin-bottom: 0 !important;
	}
	.right-contact-row h3 {
		line-height: 1.6em;
		/* letter-spacing: 4px; */
		text-align: center;
	}
	.right-contact-row h1 {
		text-align: center !important;
		font-size: 28px;
	}
	#content-contact {
		padding-top: 40px !important;
		padding-bottom: 40px !important;
	}
	.content-scroll {
		height: 100% !important;
		margin: 0 auto;
	}
	.frm-contact {
		width: 90% !important;
		float: none !important;
		margin: 0 auto !important;
	}
	.frm-contact h4 {
		line-height: 1.5em;
		padding-bottom: 4px;
	}
	.long-lbl {
		width: 80% !important;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	#chkbank,
	#chkchange {
		height: 40px !important;
	}
	.input-container i {
		font-size: 22px;
	}
	.col-days {
		float: none !important;
		width: 100% !important;
		left: 0;
	}
	.col-days label {
		width: 100%;
	}
	.chkbox-container {
		/* margin-bottom: 20px !important; */
	}
	.chkbox label {
		width: 100% !important;
	}
	.step-dots {
		margin: 0 auto !important;
	}
	#bottom-content {
		display: block;
		height: auto;
	}
	#bottom-content .bottom-row-left {
		width: 100%;
		margin: 0 auto;
	}
	#bottom-content .row-container {
		text-align: center;
		padding-top: 10px;
	}
	#bottom-content .row-container h2 {
		line-height: 1.4em;
	}
	#bottom-content .row-container p {
		line-height: 1.6em;
	}
	#content-cash {
		position: relative;
		width: 100%;
		margin: 0 auto;
		top: 10px;
		right: 0;
		left: 0;
		padding-bottom: 40px !important;
	}
	.cash-row {
		float: none;
		width: 90%;
		margin: 0 auto 20px auto;
	}
	.cash-row h4 {
		font-size: 18px;
	}
	.cash-row p {
		font-size: 15px;
	}
	.row-container .divider {
		margin: 60px auto 30px auto;
	}
	/*** MOBILE SERVICE SLIDER ***/
	.slideshow {
		width: 100%;
		height: 300px;
		position: relative;
		text-align: center;
		margin: 0 auto;
	}
	.slideshow[data-transition='zoom'] {
		overflow: hidden;
	}
	/* slideshow item */
	.slideshow--item {
		width: 100%;
		height: 360px;
		line-height: 1.5;
		position: absolute;
		top: 0;
		visibility: hidden;
	}
	.slideshow--bullet:checked + .slideshow--item {
		visibility: visible !important;
	}
	/* slideshow navigation */
	input:checked + .service-item {
		opacity: 1;
		visibility: visible;
	}
	.service-item {
		display: inline-block !important;
		width: 100% !important;
		text-align: center;
		margin: 0 auto;
		opacity: 0;
		position: absolute;
		visibility: hidden;
		transition: opacity 1s ease-in-out !important;
	}
	.slide-image {
		opacity: 1 !important;
	}
	.carousel .slides {
		/* height: 270px; */
		height: 220px;
	}
	.carousel-controls {
		display: none;
	}
	.serviceitem-controls {
		display: block !important;
		position: relative;
		top: -200px;
		z-index: 999;
		font-size: 100px;
		color: #fff;
	}
	.serviceitem-controls label {
		position: absolute;
		transition: opacity 0.2s;
		cursor: pointer;
	}
	.serviceitem-controls .prev-slide {
		left: 0;
	}
	.serviceitem-controls .next-slide {
		right: 0;
	}
	/*** MOBILE TEAM SLIDER ***/
	.members-slider,
	.member-controls {
		display: none;
	}
	.mobile-members-slider {
		display: block;
	}
	.item-container {
		width: 70% !important;
		width: 24%;
		background: #fff;
		align-self: center;
		justify-content: center;
		align-items: center;
	}
	.teamslideshow {
		width: 100%;
		min-height: 820px;
		position: relative;
		text-align: center;
		padding-bottom: 30px;
		margin: 0 auto;
	}
	/* slideshow item */
	.teamslideshow--item {
		width: 100%;
		height: 360px;
		line-height: 1.5;
		position: absolute;
		top: 0;
		visibility: hidden;
		transition: opacity 1s ease-in-out !important;
		opacity: 0;
	}
	.teamslideshow--bullet:checked + .teamslideshow--item {
		visibility: visible;
		opacity: 1;
	}
	/* slideshow navigation */
	.teamslideshow--nav {
		position: absolute;
		top: 170px;
		bottom: 0;
		width: 50%;
		display: none;
		z-index: 88;
		cursor: pointer;
		color: #c7a652;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-size: 50px;
		width: 50px;
		height: 60px;
	}
	.teamslideshow--nav-previous {
		left: 0;
		display: block;
	}
	.teamslideshow--nav-next {
		right: 0;
		display: block;
	}
	/* Radiobuttons to control the slideshow */
	.teamslideshow--bullet {
		font-size: 0.75em;
		width: 1em;
		height: 1em;
		display: inline-block;
		position: relative;
		z-index: 99;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: #ccc;
		border-radius: 1em;
		transition: 0.3s ease-out background, 0.3s ease-out transform;
		visibility: hidden;
	}
	.teamslideshow--bullet:checked {
		background: #999;
		outline: none;
		-webkit-transform: scale(1.3);
		-moz-transform: scale(1.3);
		transform: scale(1.3);
	}
	.team-pic {
		width: 100%;
		margin: 0 auto;
	}
	#team-content h2 {
		line-height: 1.8em;
	}
	.social-media {
		display: inline-block;
	}
	/*** FOOTER AREA ***/
	.left-column,
	.right-column {
		float: none !important;
		width: 100%;
		margin: 0 auto !important;
		display: inline-block;
	}
	.right-column ul {
		width: auto;
	}
	#footer-links .inner div.right-column {
		width: 380px;
		display: block;
	}
	.right-column ul {
		text-align: left !important;
		width: auto;
		margin: 0 auto;
	}
	.right-column ul li i {
		left: 0px;
	}
	.right-column ul li {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.footer-lnk-cols {
		display: none;
	}
	.mobile-footer-lnk-cols {
		display: block;
	}
	#footer-links .divider {
		margin: 50px auto 25px auto !important;
	}
	#footer-links .mid-row {
		width: 33% !important;
		padding-left: 40px;
	}
	#footer-links h2,
	.right-column ul {
		text-align: center;
	}
	footer {
		margin-bottom: 0px;
		padding-top: 0px;
	}
	footer p {
		font-size: 12px;
		line-height: 2em;
		padding: 20px;
	}
	/*** SNIPPETS ***/
	.row-regular,
	.row-medium,
	.col-left,
	.col-right {
		width: 100%;
		margin: 0 auto;
		float: none;
	}
	#footer-links .col-left,
	#footer-links .col-right {
		width: 50%;
		margin: 0;
		padding: 0;
		text-align: left;
		float: left;
	}
	#footer-links .col-left ul,
	#footer-links .col-right ul {
		list-style-type: none;
	}
	.flexbox {
		display: inline-block;
	}
	.featured-content {
		padding: 0 20px 0 20px;
		width: 100%;
		float: none;
		margin: 0 auto;
	}
	.featured-content h1 {
		text-align: center;
	}
	.featured-button-active,
	.featured-button {
		display: inline-block;
		width: 100% !important;
		margin-bottom: 20px;
		text-align: center;
	}
	.featured-button-active {
		margin-right: 0px;
	}
	.content-divider {
		margin: 40px auto 20px auto;
		width: 20%;
	}
	.spacing-left {
		padding: 20px;
	}
	.seal-img {
		width: 90%;
		margin: 0 auto;
	}
	.seal-img img {
		width: 50%;
		top: none;
		transform: none;
	}
	#content .center-content-text,
	.bottom-content-text p,
	.seal-text {
		width: 100%;
		padding: 40px 20px 0 20px;
		text-align: justify;
	}
	.cta-box img {
		width: 100%;
		height: 100%;
		display: block;
	}
	.cta-overlay {
		width: 100%;
		height: 100%;
	}
	.cta-overlay .divider {
		/* margin:0 auto; */
		margin: 10px auto 20px auto;
	}
	#content .center-content-text p {
		text-align: justify !important;
	}
	.cta-overlay p,
	.cta-overlay h3,
	.cta-overlay h2 {
		text-align: center !important;
	}
	.cta-box-fullwidth-overlay {
		padding: 40px 40px 0px 40px;
		height: auto;
		display: inline-block;
	}
	.section-white {
		padding: 0px;
	}
	.video-content {
		margin: 0px;
		height: 320px !important;
	}
	.bottom-seal-content {
		padding-bottom: 0px;
	}
	.cta-box-fullwidth img {
		width: 100%;
	}
	#guarantee {
		flex-direction: column;
	}
	#guarantee .content-wrapper {
		width: 82%;
		margin: 0 auto;
	}
	#guarantee .content-wrapper p {
		margin: 0 25px;
		text-align: justify;
	}
	#guarantee .content-wrapper #guarantee-seal-wrapper div {
		width: auto;
	}
	#guarantee-seal-wrapper {
		justify-content: center;
	}
	#guarantee .col-left .content-wrapper p {
		text-align: justify;
	}
	#guarantee .col-left .content-wrapper .slogan {
		text-align: center;
	}
	#guarantee .content-wrapper #guarantee-seal-wrapper div {
		margin-left: 20px;
	}
	#guarantee .col-right {
		width: 100%;
	}
	#guarantee .col-right .cta-box {
		width: 100%;
	}
	#guarantee .col-right .cta-box .cta-overlay {
		height: 70%;
	}
	#footer-links h4 {
		text-align: center;
	}
	#video-section h2 {
		font-size: 20px;
		line-height: 26px;
		width: 80%;
		margin: 0 auto;
	}
	#content-middle .inner .left-row .service-container {
		padding: 16px;
	}
	#content-middle .inner .left-row .service-container .prata2,
	#content-middle .inner .left-row .service-container p {
		padding: 0;
		margin: 0;
	}
	#content-middle .inner .left-row .service-container .montSemiBold {
		padding: 8px 0;
	}
	#content-cash .cash-row .header-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 20px;
	}
	.cash-row {
		padding-bottom: 16px;
	}
	.cash-row img {
		width: 80px;
		margin-right: 16px;
		padding-top: 35px;
	}
	.cash-row h4 {
		font-size: 22px;
	}
	#companies1 {
		padding: 30px 0;
	}
	#content > div.content-columns {
		width: 100%;
	}
	#content-contact > div > div.right-contact-row > div {
		width: 85%;
	}
	#content-cash > .cash-row {
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}
	header .header-wrapper {
		width: 100%;
	}
	#banner hr.divider-2,
	hr.divider-2 {
		margin-left: auto;
		margin-right: auto;
	}
	#quote-content-right > #frm-contact {
		margin-top: 40px !important;
	}
	#quote-content-right > .frm-contact label {
		margin-left: 0;
		margin-right: 0;
	}

	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-6,
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-7,
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-8,
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-9 {
		width: 130px;
		justify-self: end;
		text-align: right;
	}
	#frm-contact .chkbox-container-cash .checkbox-wrapper-9 {
		justify-self: left;
	}
	.chkbox-grid,
	#frm-contact .chkbox-container-cash {
		grid-template-rows: repeat(3, 1fr);
	}
	.checkbox-wrapper-4,
	.checkbox-wrapper-5,
	.checkbox-wrapper-6,
	#frm-contact .chkbox-container-cash .checkbox-wrapper-4,
	#frm-contact .chkbox-container-cash .checkbox-wrapper-5,
	#frm-contact .chkbox-container-cash .checkbox-wrapper-6 {
		justify-self: center;
		width: 120px;
	}
	#frm-contact .chkbox-container-cash .checkbox-wrapper-9 {
		justify-self: right;
	}
	.checkbox-wrapper-7,
	.checkbox-wrapper-8 {
		width: 100px;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper {
		min-width: 80%;
		margin-left: 10%;
		margin-right: 10%;
	}
	#about-us-team-slider > .inner .team-carousel-wrapper {
		width: -moz-calc(100% - 48px);
		width: -webikit-calc(100% - 48px);
		width: calc(100% - 48px);
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.item-container {
		width: 300px !important;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.team-item-about-content {
		height: -moz-calc(100% - 50px);
		height: -webikit-calc(100% - 50px);
		height: calc(100% - 50px);
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.team-item-about-content
		.wrapper {
		max-height: 80%;
		height: auto;
		padding-left: 16px;
	}
	.content-wrapper {
		padding-top: 153px;
	}
	#testimonial-carousel {
		padding-bottom: 30px;
	}
	#testimonial-carousel .item .photo-section .photo {
		height: calc(100% + 30px);
		width: calc(100% + 30px);
	}
	#testimonial-carousel .item .content .wrapper {
		height: 100%;
		margin-left: 54px;
	}

	#testimonial-carousel .item .content .wrapper .excerpt {
		height: calc(100% - 126px);
		margin-top: 26px;
	}
	#testimonial-carousel .item .photo-section .quote {
		font-size: 150px;
		top: 20px;
		left: 18px;
	}
	#testimonial-carousel .item .photo-section .photo .profile {
		margin-left: 1px;
	}
	#hero-image {
		margin-top: 12px;
	}
	#hero-image .wrapper {
		width: 95%;
	}
	#hero-image .wrapper p {
		width: 80%;
	}
	#testimonial-header-wrapper {
		width: 80%;
	}
}

/* 992 */
@media only screen and (max-width: 932px) {
	#quote-content-right > #frm-contact {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	#quote-content-right > #frm-contact .chkbox-container .checkbox-wrapper-3 {
		width: 132px;
	}
	#quote-content-right > #frm-contact .chkbox-grid {
		grid-template-rows: repeat(4, 1fr);
	}
	#quote-content-right > #frm-contact .chkbox-container-cash.chkbox-grid {
		grid-template-rows: repeat(5, 1fr);
	}
	#quote-content-right > #frm-contact .chkbox-container .checkbox-wrapper-4 {
		justify-self: left;
	}
	#quote-content-right > #frm-contact .chkbox-container .checkbox-wrapper-5,
	#quote-content-right > #frm-contact .chkbox-container .checkbox-wrapper-6,
	#quote-content-right > #frm-contact .chkbox-container .checkbox-wrapper-7,
	#quote-content-right > #frm-contact .chkbox-container .checkbox-wrapper-8 {
		width: 102px;
		justify-self: end;
	}
	#quote-content-right
		> #frm-contact
		.chkbox-container-cash.chkbox-grid
		.checkbox-wrapper-5 {
		justify-self: start;
	}
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-6,
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-7,
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-8,
	#quote-content-right
		> #frm-contact
		.chkbox-container.chkbox-container-cash
		.checkbox-wrapper-9 {
		width: 110px;
		justify-self: end;
		text-align: right;
	}
}

@media only screen and (max-width: 860px) {
	#video-section h2 {
		margin: -40px auto 0 auto;
	}
	.teamslideshow {
		min-height: 700px;
	}
}

@media only screen and (max-width: 790px) {
	#video-section h2 {
		margin: -60px auto 0 auto;
	}
	#team-content {
		padding: 50px;
	}
	.teamslideshow {
		min-height: 620px;
	}
}

/* @media only screen and (max-width: 768px) { */
@media only screen and (max-width: 768px) {
	#pitch .scrollable-content h3:nth-child(1) {
		margin-top: 0;
	}
	#hero-image .cta-box {
		width: 60%;
		margin-top: 50px;
	}
	#video-section h2 {
		margin: 10px auto 0 auto;
		line-height: 1.6em;
		width: 60%;
	}
	#content p {
		text-align: justify;
	}
	#intro-more-content > div > p {
		text-align: left !important;
	}
	.cta-overlay h2 {
		text-align: left;
	}
	#pitch,
	#faq {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 100%;
		height: auto;
		height: auto;
	}
	#pitch .col-right,
	#faq .col-right {
		margin-top: 25px;
	}
	#pitch .scroller {
		height: auto;
		color: #000;
	}
	#pitch .scroller::-webkit-scrollbar,
	#faq .scroller::-webkit-scrollbar {
		display: none;
	}
	#faq {
		flex-direction: column-reverse;
	}
	#pitch .scrollable-content h3,
	#faq .scrollable-content h3 {
		text-align: center;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	#pitch .cta-overlay h3.header-text {
		margin-bottom: 0px;
	}
	#faq .col-left .cta-box {
		width: 100%;
	}
	#pitch div.col-left div div ul {
		width: 85%;
	}
	#pitch .scroller .scrollable-content,
	#faq .scroller .scrollable-content {
		margin: 0 auto;
		width: 82%;
		padding: 0 25px;
	}
	.btn {
		width: 180px;
		height: 55px;
		line-height: 55px;
		font-size: 16px;
	}
	#video-section div.black-bar {
		display: none;
	}
	#video-section {
		display: inline-block;
		height: 380px;
	}
	#video-section div.video-container .video-player {
		height: 427px;
	}
	#video-section div.video-container {
		position: static;
	}
	.rm-mobile {
		display: none;
	}
	#guarantee .col-left .content-wrapper p.note {
		margin-bottom: 0;
		padding-bottom: 20px;
	}
	#content h3.header-times {
		margin-bottom: 0;
	}
	.frm-contact h4,
	.frm-contact h3 {
		margin-bottom: 10px;
	}
	.right-contact-row h4.prata2 {
		text-align: center;
		margin-bottom: 10px !important;
	}
	.right-contact-row h5 {
		margin-bottom: 20px;
	}
	.row-container .divider {
		margin: 30px auto 30px auto;
	}
	.member-info {
		padding: 25px 20px 30px 20px;
		width: 100%;
		text-align: left;
	}
	.member-info h4 {
		font-size: 20px;
		font-weight: 600;
		padding: 0;
	}
	.text {
		top: 10%;
		margin-top: 35px;
		padding-left: 0;
		margin-right: 0;
		padding-right: 0;
	}
	#bottom-content div.bottom-row-left div.row-container h2 {
		margin-bottom: 24px;
	}

	.right-contact-row .divider {
		margin-top: 16px !important;
	}
	#content-contact > div > div.right-contact-row {
		padding-left: 0px;
		padding-top: 40px !important;
	}
	.frm-contact h3,
	.frm-contact h5,
	.frm-contact p {
		padding-bottom: 0px;
		width: 100%;
	}
	.frm-contact h3 {
		width: 100%;
		margin: 0 auto;
		margin-bottom: 16px;
	}
	.teamslideshow {
		min-height: 572px;
		padding-bottom: 0;
	}
	#intro-more-content {
		display: none;
	}
	.read-more-link {
		display: inline;
	}
	.video h5 {
		background-color: #fff;
	}
	.video iframe {
		height: 430px;
	}
	.step-dots {
		width: 45%;
	}
	#banner-info {
		display: inline-block;
	}
	.dot-navigation {
		position: absolute;
	}
	#footer-links > div > div.left-column {
		width: 50%;
	}
	#footer-links .inner div.right-column {
		width: 50%;
		display: inline-block;
	}
	#content-contact > div > div.right-contact-row > h5 {
		padding-left: 52px;
	}
	#content-contact {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		margin-top: 40px !important;
	}
	.right-contact-row h3.montSemiBold {
		font-size: 32px;
		line-height: 1.3em;
		margin-bottom: 25px;
		letter-spacing: 0.75px;
		text-align: center;
	}
	div.chkbox-container.chkbox-row input,
	input[type='checkbox'] + label span {
		width: 36px;
		height: 36px;
	}
	.frm-contact .control-wrapper .chkbox label,
	.chkbox-container h5,
	.chkbox-container p {
		font-size: 16px !important;
		display: inline;
	}
	.chkbox-container {
		flex-direction: column;
		justify-content: space-between;
		margin-top: 30px;
	}
	input[type='checkbox']:checked + label:after {
		content: '';
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		color: #fff;
		transition: all 0.2s;
		cursor: pointer;
		font-size: 36px;
		border: solid white;
		border-width: 0 4px 4px 0;
		top: 0;
		height: 20px;
		width: 10px;
		left: 10px;
	}
	.chkbox-grid .chkbox input[type='checkbox']:checked + label:after {
		top: 9px;
		height: 23px;
		width: 10px;
		left: 11px;
	}
	input[type='checkbox']:checked + label span {
		background: #c6a54b;
	}
	input[type='checkbox'] + label span {
		display: inline-block;
		width: 36px;
		height: 36px;
		margin: -2px 10px 0 0;
		vertical-align: middle;
		background: #fff;
		cursor: pointer;
		border-radius: 4px;
	}
	div.form-spacer {
		/* margin: 20px 0; */
	}
	div.btn-learn-more {
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
	}
	#content .center-content,
	#content > div.content-columns,
	#content hr.divider-2,
	.content-columns .leftside-column h3,
	.content-columns .rightside-column {
		margin-bottom: 0px;
		padding-bottom: 0;
	}
	#content {
		padding-top: 0;
	}
	#content .leftside-column p {
		text-align: center;
		margin-bottom: 0;
	}
	.p-header {
		margin-top: 0;
	}
	.content-columns .rightside-column {
		float: none;
	}
	.content-columns .rightside-column iframe {
		height: calc(100vw / 1.8);
	}

	#banner {
		// Added after Live
		margin-top: 42px;
	}

	#banner,
	#content .center-content,
	.content-columns .leftside-column {
		margin-bottom: 40px;
	}
	#content hr.divider-2,
	.content-columns .leftside-column h3 {
		margin-bottom: 40px;
	}
	.content-columns .rightside-column {
		margin-bottom: 0px;
	}
	#content-middle div.inner.content-middle-wraper {
		padding: 40px 0;
	}
	#intro-more-content div.item-box.align-left {
		margin-top: 40px;
	}
	.content-columns .leftside-column h3 {
		line-height: 22px;
	}
	#banner-mid {
		flex-direction: column;
		margin-top: 40px;
	}
	#content-counter-wrapper {
		flex-direction: column;
	}
	.mid-row-divider {
		width: 150px;
		height: 2px;
	}
	#banner-mid .mid-row {
		padding: 50px 0;
	}
	.content-bg {
		margin-top: 0;
	}
	#content-middle div.inner.content-middle-wraper {
		flex-direction: column;
		justify-content: space-between;
	}
	.left-row-content-middle {
		width: 100%;
		text-align: center;
		padding-right: 0;
	}
	.right-row-content-middle {
		margin-left: 0px;
		margin-top: 40px;
	}
	#service-slider {
		margin: 0 auto;
	}
	.carousel .slides {
		height: 190px;
	}
	.inner-controls {
		right: 0;
	}
	.right-contact-row .divider-2 {
		margin: 0 auto 24px auto;
	}
	h2.montSemiBold,
	h3.prata2,
	.right-contact-row p.small {
		text-align: center;
	}
	#content-contact > div > div.right-contact-row > div {
		margin-top: 24px;
	}
	#bottom-content {
		margin-top: 40px;
	}
	#bottom-content .row-container {
		width: 95%;
		margin: 0 auto;
		padding: 40px 0;
	}
	#content-cash > .cash-row {
		width: 100%;
		padding: 0 24px 24px 24px;
	}
	#bottom-content .row-container p {
		line-height: 2em;
		text-align: left;
		margin-bottom: 0;
	}
	#bottom-content .row-container p.small {
		text-align: center;
		margin-bottom: 24px;
	}
	.cash-row h3 {
		font-size: 24px;
	}
	#content-cash {
		padding-bottom: 0 !important;
	}
	#team-content {
		margin-top: 0;
	}
	.row-container .divider-2 {
		margin-top: 0;
	}
	#banner-info {
		padding: 4px 0;
	}
	#banner-info .mid-row {
		padding-top: 4px;
		padding-bottom: 4px;
	}
	#mobile-menu {
		box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
		-webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
		-moz-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
	}
	#quote-content {
		background-size: auto 300px;
	}
	#quote-content-left > img {
		height: 260px;
		margin-top: 40px;
	}
	#quote-content-container {
		flex-direction: column;
		margin-bottom: 40px;
	}
	#headline-wrapper h1 {
		font-size: 40px;
	}
	#headline-wrapper h3.prata {
		font-size: 24px;
	}
	#headline-wrapper p {
		font-size: 16px;
	}
	#headline-wrapper h1,
	#headline-wrapper h3.prata,
	#headline-wrapper p,
	#headline-wrapper hr {
		margin-bottom: 16px;
	}
	#headline-content {
		height: 300px;
	}
	#quote-content-container {
		text-align: center;
	}
	#contact-info-wrapper {
		text-align: center;
	}
	#left-content-text-wrapper > a.btn-wrapper {
		margin-left: auto;
		margin-right: auto;
	}
	#left-content-text-wrapper p {
		padding: 0px;
		margin: 0;
		line-height: 2em;
	}
	#contact-info-wrapper > .mid-row-divider-wrapper {
		margin-left: 8px;
		margin-right: 8px;
	}
	#quote-content-left h3 {
		font-size: 32px;
	}
	#note-wrapper {
		text-align: center;
	}
	#left-content-text-wrapper {
		padding-bottom: 40px;
	}
	#note-wrapper {
		margin-bottom: 40px;
	}
	.bg-dots {
		background-size: 75%, 75%;
	}
	div.callback-button {
		margin-left: auto;
		margin-right: auto;
	}
	#quote-content {
		background-size: auto 209px, 100% 250px, 50%, 50%;
		background-position: center 41px, left top, left bottom, right bottom;
		background-position: center 41px, left top, left bottom, right bottom;
		background-position: center 41px, left top, left bottom, right bottom;
	}
	#contact-mobile-wrapper {
		margin-bottom: 36px;
	}
	#quote-content-container,
	#quote-content {
		margin-bottom: 0px;
	}
	#left-content-text-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
	#quote-content-right > #frm-contact {
		display: none;
	}
	r
		#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper {
		width: 150px;
	}
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper-7,
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper-8,
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper-9 {
		justify-self: end;
		width: 140px;
	}
	.chkbox-grid,
	.chkbox-grid.chkbox-container-cash {
		grid-template-rows: repeat(3, 1fr);
	}
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-6 {
		justify-self: left;
	}
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-7,
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-8,
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-9 {
		width: 140px;
	}

	.about-us-section .section-header {
		padding: 18px 24px;
	}
	.section-content-wrapper img {
		margin-top: calc(88px / 2);
		height: calc(100% - (88px / 2));
	}
	#about-us-section-help .content,
	#about-us-section-service .content {
		height: 100%;
	}
	.about-us-section .section-header h3 {
		font-size: 32px;
	}
	#about-us-section-service {
	}
	.section-content-wrapper {
		justify-content: flex-start;
	}
	.section-content-wrapper img {
		height: auto;
		margin-top: 40px;
		padding-top: 0;
	}
	#about-us-section-help .section-col {
		height: -moz-calc(342px + 40px);
		height: -webikit-calc(342px + 40px);
		height: calc(342px + 40px);
	}
	.about-us-section .section-content {
		margin-top: 80px;
	}
	#about-us-section-service .section-col {
		height: 354px;
	}
	#about-us-section-story {
		height: 296px;
	}
	#about-us-section-story .content {
		height: 100%;
	}
	#testimonial-carousel .item .content .wrapper .excerpt {
		height: 106px;
	}
	#header-image-wrapper {
		width: 100%;
	}
	#welcome-header-text-wrapper {
		width: 100%;
		text-align: center;
		color: #ffffff;
	}
	#welcome-header-text-wrapper p {
		font-weight: 600;
	}
	#welcome-header-image {
		filter: brightness(0.5);
	}
	#welcome-header-section .inner {
		justify-content: center;
		align-items: center;
	}
	#instruction-section {
		margin-top: 87px;
		margin-bottom: 56px;
	}
	#black-box {
		width: 100%;
		border-radius: 0px;
		padding-top: 48px;
		padding-bottom: 28px;
	}
	#black-box .content {
		padding: 0;
		margin: 0 auto;
	}
	#header-image-wrapper .arrow-down {
		left: calc(50% - 12px);
	}
	#instruction-box {
		position: relative;
		width: 95%;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 56px;
		top: 0;
	}
	#instruction-box > div > hr.divider-2.divider-gold {
		margin-left: 0;
		margin-right: 0;
	}
	#black-box .content {
		padding: 0px;
	}
	#instruction-box .content {
		padding: 48px 32px 52px 32px;
	}
	#banner-mid-content::before .webp #banner-mid-content::before {
		background-position: -1150px center;
		height: 380px;
	}

	// Add
	#pitch {
		.col-right {
			.cta-box {
				img {
					height: 580px;
				}
			}
		}
	}

	// End Add
}

/* 768 */
@media only screen and (max-width: 736px) {
	#hero-image h1 {
		font-size: 38px;
		line-height: 46px;
	}
	.p-header {
		margin-top: 30px;
	}
	.p-header h4 {
		font-size: 18px !important;
	}
	.p-header .icon-service {
		height: 40px;
		padding-right: 16px;
	}
	.teamslideshow {
		min-height: 540px;
	}
	.step-dots {
		width: 70%;
	}
	.footer-wrapper {
		flex-direction: column;
	}
	#footer-links .left-column {
		width: 100%;
	}
	#footer-links > div > div.left-column {
		width: 100%;
	}
	#footer-links .col-left,
	#footer-links .col-right {
		text-align: center;
		list-style-type: none;
	}
	#footer-links .inner div.right-column {
		display: block;
		margin-top: 40px !important;
	}
	div.btn-learn-more {
		min-height: 50px;
		min-width: 130px;
		width: 150px;
	}
	.text {
		top: 36px;
	}
	#video-section div.video-container .video-player {
		height: 422px;
	}
	#content-middle {
		min-height: 518px;
	}
	.chkbox-grid .chkbox input[type='checkbox']:checked + label:after {
		top: 11px;
		height: 22px;
		width: 7px;
		left: 12px;
	}
	.chkbox-grid .checkbox-wrapper-4,
	.chkbox-grid .checkbox-wrapper-5,
	.chkbox-grid .checkbox-wrapper-6 {
		justify-self: left;
	}
	/* .chkbox-grid.chkbox-container-cash .checkbox-wrapper-6, */
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-7,
	.chkbox-grid.chkbox-container-cash .checkbox-wrapper-8 {
		justify-self: right;
		width: 140px;
	}
	#headline-about-us .content h1 {
		font-size: 48px;
	}
	#headline-about-us .content {
		width: 35%;
	}
}

/*736 */
@media only screen and (max-width: 736px) and (orientation: landscape) {
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.team-item-about-content
		.wrapper {
		width: 45%;
	}
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.team-item-about-content
		.wrapper {
		width: 35%;
	}
}

/* tablet view */
@media only screen and (max-width: 670px) {
	.rm-mobile {
		display: none;
	}
	#video-section h2 {
		font-size: 18px;
		line-height: 24px;
	}
	.spacer-lg {
		height: 30px;
	}
	#hero-image {
		background-position: 40%;
		background-size: 1100px;
		min-height: 50vh;
		/* margin-top: 164px; */
	}
	#hero-image h1 {
		font-size: 30px;
		line-height: 36px;
	}
	#hero-image .center-content-divider {
		margin-top: 10px;
		width: 16%;
	}
	#hero-image .cta-box {
		width: 80%;
		justify-content: space-evenly;
	}
	h2,
	h3,
	#intro h2,
	#pitch .scrollable-content h3,
	#faq .scrollable-content h3 {
		line-height: 30px;
		font-size: 22px;
	}
	#pitch .scrollable-content h3 {
		margin-bottom: 20px;
		margin-top: 45px;
	}
	#pitch .cta-overlay {
		width: 60%; // Add/Edit width: 75%
		padding: 30px;
		text-align: center;
		justify-content: center;
	}
	#pitch .cta-overlay h3.header-text,
	#faq h3.header-text {
		font-size: 28px;
		line-height: 36px;
	}
	.montMedium {
		font-size: 18px !important;
	}
	#video-section {
		display: inline-block;
		height: 270px;
	}
	#video-section div.video-container .video-player {
		height: 370px;
	}
	#faq {
		margin-top: -4px;
	}
	#faq h4 {
		margin-bottom: 10px;
	}
	#pitch .scroller .scrollable-content,
	#faq .scroller .scrollable-content {
		padding: 0;
	}
	#guarantee .content-wrapper {
		padding-left: 0;
	}
	#guarantee-seal-wrapper {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	#guarantee .col-left .content-wrapper img {
		margin-bottom: 25px;
		width: 50%;
		height: 50%;
	}
	#guarantee .content-wrapper #guarantee-seal-wrapper div {
		margin: 0;
		padding: 0;
	}
	.bottom-center-content h4 {
		font-size: 35px;
		padding: 0 16px;
		line-height: 45px;
	}
	.breadcrumb {
		width: 60%;
		margin: auto auto;
	}
	.content-columns .rightside-column {
		padding-bottom: 0;
	}
	.teamslideshow {
		min-height: 520px;
	}
	.video iframe {
		height: 370px;
	}
	#banner-mid {
		height: 760px;
		padding-top: 0;
	}
	.item-container {
		width: 80% !important;
	}
	.teamslideshow--nav-previous {
		left: -16px;
	}
	.teamslideshow--nav-next {
		right: -16px;
	}

	#headline-about-us .content {
		width: 50%;
	}
	#headline-about-us {
		background-size: 68%;
	}
	#testimonial-header-wrapper h3 {
		font-size: 24px;
	}
	#testimonial-header-wrapper hr {
		margin-top: 20px;
	}
	#testimonial-header-wrapper {
		margin-bottom: 0;
	}
	#testimonial-carousel {
		margin-top: 64px;
		padding-bottom: 0;
		margin-bottom: 0;
	}
	#testimonial-carousel-container {
		position: relative;
	}
	#contact-testimonial-section .inner,
	#contact-form-section .inner {
		width: 90%;
	}
	#contact-form-info-panel .content .info-wrapper .ic-wrapper {
		margin-right: 8px;
	}
	#contact-form-info-panel .content .info-wrapper {
		margin-bottom: 24px;
	}
	#contact-form-info-panel .content .info-wrapper .ic-wrapper .fa {
		font-size: 16px;
	}
	#contact-testimonial-section {
		margin-bottom: 36px;
	}
	#testimonial-carousel .item {
		flex-direction: column;
		width: calc(100% - 100px);
		padding-left: 50px;
		padding-right: 50px;
	}
	#testimonial-carousel .item .photo-section {
		width: calc(100% - 30px);
		height: calc(100% - 30px);
	}
	#testimonial-carousel .item .content {
		width: 100%;
		margin-top: 86px;
	}
	#testimonial-carousel .item .content .wrapper {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
	#testimonial-carousel .item .content .wrapper .excerpt,
	#testimonial-carousel .item .content .wrapper .info p,
	#testimonial-carousel .item .content .wrapper .info .name {
		text-align: center;
	}
	#testimonial-carousel .item .content .wrapper .excerpt {
		height: auto;
	}
	#testimonial-carousel .scroll-control {
		display: none;
	}
	#testimonial-carousel-container .scroll-control-mobile {
		display: flex;
		width: 100%;
		justify-content: space-between;
		position: absolute;
		top: calc(50%);
		z-index: 9999;
	}
	#contact-form-section {
		margin-top: 52px;
		margin-bottom: 48px;
	}
	#contact-form-form-panel .content {
		width: 80%;
	}
	#callback-option-container {
		margin-left: 32px;
		margin-right: 32px;
		width: 100%;
	}
	#select-callback-pref {
		flex-direction: column;
	}
	#contact-form-form-panel
		.content
		#callback-option-container
		.form-input-wrapper.best-time {
		margin-bottom: 32px;
	}
	#contact-form-form-panel
		.content
		#callback-option-container
		.form-input-wrapper {
		margin-left: 0;
		margin-right: 0;
	}
	#contact-form-form-panel .control-wrapper .chkbox label span.label {
		line-height: 1.6em;
	}
}

/* 670 */
@media only screen and (max-device-width: 812px) and (orientation: landscape) {
	.video {
		background-color: #fff;
	}
	.video iframe {
		margin-top: -12px;
	}
	#hero-image {
		min-height: 100vh;
	}
}

@media only screen and (max-device-width: 670px) and (orientation: landscape) {
	#pitch .cta-overlay {
		padding: 30px 60px;
	}
	#banner-mid {
		height: 800px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	#banner-mix .mid-row {
		padding-top: 0;
		padding-bottom: 0;
	}
	.video {
		background-color: #fff;
	}
	.video h2 {
		margin-top: -25px;
	}
}

@media only screen and (max-width: 570px) {
	#hero-image {
		background-position: 45%;
		background-size: 900px;
		min-height: 40vh;
	}
	.teamslideshow {
		min-height: 450px;
	}
	.teamslideshow--nav-previous {
		width: 30px;
		left: 0;
	}
	.teamslideshow--nav-next {
		width: 30px;
		right: 0;
	}

	// Add
	#guarantee {
		.col-right {
			.cta-box {
				img {
					height: 604px;
					object-position: 36%;
				}

				.cta-overlay {
					height: 100%;
				}
			}
		}
	}
}

@media only screen and (max-width: 570px) and (orientation: landscape) {
	#hero-image {
		min-height: auto;
		height: 520px;
		background-size: cover;
	}
}

/* small phones */
@media only screen and (max-width: 450px) {
	#video-section h2 {
		font-size: 16px;
		line-height: 22px;
		margin: 4px auto 0 auto;
		width: 90%;
	}

	#hero-image {
		background-position: 45%;
		background-size: cover;
	}
	#hero-image h1 {
		font-size: 30px;
		line-height: 36px;
	}
	#hero-image .center-content-divider {
		margin-top: 10px;
		width: 16%;
	}
	#hero-image .cta-box {
		width: 90%;
		justify-content: space-evenly;
	}
	.btn {
		width: 100%;
		height: 45px;
		line-height: 45px;
		font-size: 12px;
		margin: 0 10px;
	}
	#video-section {
		display: inline-block;
		height: 230px;
	}
	#video-section div.video-container .video-player {
		height: 230px;
	}
	#faq {
		margin-top: -4px;
	}
	#faq h4 {
		font-size: 16px;
	}
	#pitch .cta-overlay h3.header-text,
	#faq h3.header-text,
	#guarantee .col-right .cta-box .cta-overlay h3.header-text {
		font-size: 22px;
		line-height: 32px;
		margin-bottom: 0;
	}
	#pitch .scrollable-content h3,
	#faq .scrollable-content h3 {
		width: 100%;
	}
	#pitch a.btn {
		margin-top: 14px;
	}
	#guarantee .col-right .cta-box .cta-overlay p {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 8px;
	}
	#guarantee .content-wrapper p {
		margin: 0;
	}
	#guarantee .col-right .cta-box .cta-overlay {
		padding: 30px 10px;
	}
	#guarantee a.btn {
		margin-top: 10px;
	}
	#guarantee .col-left .content-wrapper .slogan {
		line-height: 27px;
		padding-top: 24px;
	}
	#guarantee-seal-wrapper {
		margin-bottom: 24px;
	}
	.cta-overlay .divider {
		margin: 8px auto 16px auto;
	}
	#content p,
	#intro .content-wrapper p {
		margin-bottom: 12px;
	}
	.montMedium {
		padding-bottom: 12px !important;
	}
	#pitch .btn {
		margin-left: 0;
		margin-right: 0;
	}
	.p-header .icon-service {
		padding-right: 10px;
	}
}

/* video for iphone 6/7/8 plus*/
@media only screen and (max-width: 414px) {
	#footer-links .inner div.right-column {
		width: 300px;
		display: block;
		margin-top: 40px !important;
	}
	.text h3 {
		font-size: 20px !important;
	}
	#banner .text h1 {
		font-size: 26px !important;
		padding-top: 0;
	}
	.text p {
		font-size: 12px !important;
	}
	.text {
		top: 12px;
	}
	div.btn-learn-more {
		min-height: 50px;
		min-width: 130px;
		width: 150px;
	}
	.btn-learn {
		margin-top: 18px;
		padding: 8px 20px !important;
		font-size: 14px !important;
	}
	#banner-info {
		padding: 10px 0;
	}
	#banner-info .mid-row {
		width: 80%;
		margin: 0 auto;
		font-size: 12px;
	}
	#banner-info i {
		font-size: 18px;
	}
	#intro .content-wrapper {
		margin-top: 0px;
	}
	#content h3.header-times {
		font-size: 26px;
	}
	.header-gold {
		font-size: 44px;
		padding-bottom: 10px;
	}
	#content hr {
		margin: 24px auto 24px auto;
	}
	#intro .content-wrapper p {
		padding: 0;
		margin: 0;
	}
	.left-row-content-middle .service-container h3 {
		font-size: 24px !important;
		line-height: 1.5em;
	}
	.left-row-content-middle .service-container h1 {
		padding: 8px 0 !important;
		font-size: 28px !important;
		line-height: 1.2em;
	}
	.service-container p {
		font-size: 12px;
		line-height: 1em;
	}
	#service-slider {
		width: 90%;
		height: 232px;
	}
	.video h5 {
		line-height: 1.3em;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-left: 16px;
		padding-right: 16px;
	}
	.frm-contact {
		padding-top: 25px;
	}
	.frm-contact h4 {
		margin-bottom: 16px;
	}
	.frm-contact h4 {
		line-height: 1.3em;
		font-size: 24px;
	}
	.right-contact-row h4.prata2 {
		line-height: 1.1em;
		letter-spacing: 1px;
		font-size: 26px;
	}
	#content p#header-content,
	#content .leftside-column p {
		text-align: left;
	}
	#bottom-content div.bottom-row-left div.row-container h2 {
		margin: 18px auto;
		line-height: 1.2em;
	}
	#content-contact div div.right-contact-row div ul li p {
		padding-right: 16px;
	}
	#team-content h3 {
		font-size: 24px;
		line-height: 1.5em;
		width: 60%;
		margin: 0 auto;
	}
	#team-content {
		padding: 24px 10px;
	}
	#team-content hr {
		margin: 10px auto 20px auto;
	}
	.member-info {
		padding: 16px;
	}
	.member-info a {
		font-size: 12px;
	}
	.teamslideshow {
		min-height: 440px;
		padding-bottom: 0;
	}
	#banner-mid {
		padding-top: 0;
		height: auto;
		margin-top: 40px;
	}
	.video {
		background-color: #fff;
	}
	.video iframe {
		margin-top: -5px;
	}

	#content-contact > div > div.right-contact-row {
		padding-top: 35px !important;
	}
	#hero-image .cta-box {
		margin-top: 0 !important;
	}
	.video iframe {
		height: 230px;
	}
	.tab hr.divider-bottom {
		margin-bottom: 15px;
	}
	#content-contact > div > div.right-contact-row > h5 {
		padding-left: 25px;
	}
	.chkbox-container {
		flex-direction: column;
	}
	.chkbox-container h5 {
		display: block;
		width: 100%;
		margin-top: 0px !important;
	}
	.chkbox-container {
		margin-bottom: 0 !important;
	}
	.chkbox-container .control-wrapper {
		justify-content: space-between;
	}
	.form-spacer-small {
		display: none;
	}
	.chkbox-grid {
		grid-template-rows: repeat(4, 1fr);
	}
	#content-middle div.inner.content-middle-wraper {
		padding: 40px 0;
	}
	#content p#header-content {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	#footer-links {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.service-container h2.montSemiBold {
		width: 75%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	#bottom-content .row-container p.small {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
	#frm-contact-mobile {
		height: 584px;
	}
	#content-contact > div > div.right-contact-row > div {
		width: 100%;
	}
	#headline-wrapper {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
	#headline-content {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	#headline-wrapper h1 {
		line-height: 1.2em;
	}
	#headline-wrapper h3.prata {
		margin-bottom: 13px;
	}
	#headline-wrapper p {
		margin-bottom: 0px;
	}
	#quote-content-left > img {
		height: 200px;
		margin-top: 40px;
	}
	#quote-content {
		background-size: auto 209px, 100% 250px, 50%, 50%;
	}
	#contact-info-wrapper {
		flex-direction: column;
		padding-bottom: 32px;
	}
	#contact-info-wrapper > .mid-row-divider-wrapper {
		display: none;
	}
	.contact-info-item {
		width: 100%;
		max-width: 100%;
	}
	#contact-info-wrapper div.contact-info-item:last-child {
		margin-top: 32px;
	}
	#note-wrapper {
		margin-bottom: 36px;
	}
	#quote-content-left h3 {
		margin-left: auto;
		margin-right: auto;
		width: 90%;
	}
	.bg-dots {
		background-size: 50%, 50%;
		background-position: left center, right center;
	}
	#quote-content-left #frm-contact-mobile .chkbox-container-cash {
		grid-template-rows: repeat(5, 1fr);
	}
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper-7,
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper-8,
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper-9 {
		justify-self: end;
		width: 130px;
	}
	.chkbox-grid.chkbox-container-cash {
		grid-template-rows: repeat(5, 1fr);
	}
	.chkbox-grid {
		grid-template-rows: repeat(4, 1fr);
	}
	.chkbox-grid .checkbox-wrapper-4 {
		justify-self: left;
	}
	.chkbox-grid .checkbox-wrapper-5,
	.chkbox-grid .checkbox-wrapper-6,
	.chkbox-grid .checkbox-wrapper-7,
	.chkbox-grid .checkbox-wrapper-8 {
		justify-self: left;
	}
	#headline-content.bg-50-gray {
		background: #dfdfdf;
	}
	#headline-content,
	#headline-content .full-height {
		height: auto;
	}
	#headline-about-us {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
		background: none;
		height: auto;
	}
	#headline-about-us .content {
		display: flex;
		width: 95%;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	#headline-about-us .content div.callback-button {
		margin-left: auto;
		margin-right: auto;
	}
	#headline-about-us .content h1 {
		font-size: 48px;
		margin-top: 2px;
	}
	#headline-about-us .content p {
		margin-top: 10px;
	}
	.about-us-section .section-header {
		position: relative;
	}
	#about-us-section-service .section-col,
	#about-us-section-help .section-col {
		height: 100%;
	}
	.about-us-section .section-col {
		flex-direction: column;
		margin-top: 0px;
	}
	#about-us-section-help {
		margin-top: 0px;
		height: auto;
		padding-top: 0px;
		padding-bottom: 0px;
		background: none;
	}
	.section-content-wrapper img {
		margin-top: 0px;
	}
	.about-us-section .section-header h3 {
		text-align: center;
	}
	.about-us-section .section-content {
		margin-top: 0;
		padding-bottom: 16px;
	}
	#about-us-section-story p,
	.about-us-section .section-content p {
		text-align: left;
	}
	.about-us-section .section-content div.callback-button {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
	}
	#about-us-section-story {
		height: auto;
		margin-top: 0px;
		flex-direction: column-reverse;
	}
	#about-us-section-story {
		margin-bottom: 0px;
	}
	#about-us-section-service {
		margin-top: 0;
		height: auto;
		background: none;
	}
	#about-us-banner {
		margin-top: 0;
	}
	#about-us-team-slider {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	#about-us-banner-bottom div h2 {
		line-height: 1.4em;
		text-align: center;
	}
	.no-webp #about-us-banner-bottom::before,
	#about-us-banner-bottom::before {
		background-position: center;
	}
	.no-webp #about-us-banner > #banner-mid-content::before,
	#about-us-banner > #banner-mid-content::before {
		background-position: -1000px center;
	}
	.no-webp #about-us-banner > #banner-mid-content::before,
	#about-us-banner > #banner-mid-content::before {
		background-position: -1000px center;
	}
	#about-us-banner > #banner-mid-content h2 {
		font-size: 36px;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.item-container
		.member-info
		p,
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.item-container
		.member-info
		a {
		font-size: 14px;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.team-item-about-content {
		display: none;
	}
	#about-us-team-slider .team-carousel-wrapper .team-about-us-profile-label {
		display: block;
	}
	#about-us-team-slider > .inner .team-carousel-wrapper {
		overflow: visible;
		overflow-y: visible;
		overflow-x: hidden;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	#about-team-scroll-controll {
		width: 100%;
		flex-direction: row;
		right: 0;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper
		.item-container {
		width: 100% !important;
	}
	#about-us-team-slider
		.team-carousel-wrapper
		.team-carousel
		.team-item-wrapper {
		min-width: 90%;
		margin-left: 5%;
		margin-right: 5%;
	}
	#about-us-team-slider .team-carousel-wrapper .team-carousel {
		height: auto;
	}
	#about-us-team-slider .team-carousel-wrapper .team-about-us-profile-label {
		margin-top: 16px;
	}
	#about-us-team-slider .team-carousel-wrapper .team-about-us-profile-label p {
		font-family: 'Montserrat Light';
		font-size: 16px;
		font-weight: 400;
		line-height: 2em;
		color: black;
	}
	.about-us-section {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	#hero-image {
		height: 70vh;
	}
	#hero-image .wrapper h1 {
		font-size: 32px;
	}
	#hero-image .wrapper h3 {
		font-size: 24px;
		margin-bottom: 22px;
	}
	#hero-image .wrapper p {
		line-height: 1.6em;
		margin-bottom: 0;
	}
	#hero-image .wrapper hr {
		margin-top: 26px;
		margin-bottom: 28px;
	}
	#contact-form-info-panel .content h3 {
		font-size: 24px;
		text-align: center;
	}
	#contact-form-info-panel .content hr {
		margin-top: 28px;
		margin-bottom: 32px;
	}
	#contact-form-info-panel .info-wrapper span {
		font-size: 12px;
		text-align: center;
	}
	#contact-form-info-panel .content {
		align-items: center;
		margin-bottom: 26px;
	}
	#contact-form-form-panel .content {
		margin: 56px 24px;
	}
	#mapContainer {
		height: 350px;
	}
	.cta-box {
		height: auto;
	}
	#welcome-header-text-wrapper h1 {
		font-size: 48px;
	}
	#header-image-wrapper .arrow-down {
		left: calc(50% - 30px);
	}
	#instruction-box {
		width: 90%;
	}
	#black-box .content {
		max-width: 90%;
	}
	#welcome-header-section {
		margin-top: 12px;
	}
}

/* 414 */
/* video for iphone 6/7/8 */
@media only screen and (max-width: 375px) {
	.video iframe {
		height: 220px;
	}
	.teamslideshow {
		min-height: 420px;
	}
	#content-middle .inner .left-row .service-container .montSemiBold {
		font-size: 28px !important;
	}
	.left-row-content-middle h3.small {
		font-size: 12px !important;
	}
	#video-section div.video-container .video-player {
		height: 220px;
	}
	.input-container,
	.frm-contact label,
	.button-controls-container,
	.input-container-select,
	.comment-container,
	.frm-contact .long-lbl,
	.chkbox-container h5,
	.frm-contact h5.checkboxHeaderText {
		width: 100% !important;
	}
	.frm-contact button {
		width: 90%;
	}
	.chkbox-container {
		width: 100% !important;
	}
	.chkbox-container .control-wrapper div:nth-child(1) {
		margin-right: 0;
	}
	.chkbox-container .control-wrapper {
		justify-content: space-evenly;
	}
	.checkbox-wrapper {
		width: 160px;
	}
	.content-columns .leftside-column h3 {
		line-height: 1.6em;
	}
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper.checkbox-wrapper-6,
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper.checkbox-wrapper-7,
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper.checkbox-wrapper-8,
	#quote-content-left
		#frm-contact-mobile
		.chkbox-container-cash
		.checkbox-wrapper.checkbox-wrapper-9 {
		justify-self: end;
		width: 150px;
	}
	#frm-contact-mobile .chkbox-grid,
	#frm-contact-mobile .chkbox-grid {
		justify-self: right;
		width: 160px;
	}

	// Add
	#pitch .cta-overlay {
		width: 70%;
	}

	#guarantee {
		.col-right {
			.cta-box {
				img {
					height: 460px;
				}
			}
		}
	}

	// End Add
}

/* 375 */
/* video for iphone 6/7/8 */
@media only screen and (max-width: 320px) {
	.inner-controls {
		width: 20px;
	}
	#video-section {
		display: inline-block;
		height: 210px;
	}
	#video-section div.video-container .video-player {
		height: 188px;
	}
	.text h3 {
		font-size: 20px !important;
	}
	#banner .text h1 {
		font-size: 26px !important;
		padding-top: 0;
	}
	.text p {
		font-size: 12px !important;
	}
	.btn-learn {
		margin-top: 18px;
		padding: 8px 20px !important;
		font-size: 14px !important;
	}
	#banner-info {
		padding: 10px 0;
	}
	#banner-info .mid-row {
		width: 80%;
		margin: 0 auto;
		text-align: left;
		font-size: 12px;
	}
	#banner-info i {
		font-size: 18px;
	}
	#content h3.header-times {
		font-size: 26px;
	}
	.header-gold {
		font-size: 44px;
	}
	#content hr {
		margin: 16px auto 40px auto;
	}
	.service-container h3 {
		font-size: 24px !important;
		line-height: 1.5em;
	}
	.service-container h1 {
		padding: 8px 0 !important;
		font-size: 28px !important;
		line-height: 1.2em;
	}
	.service-container p {
		font-size: 12px;
		line-height: 1em;
	}
	#service-slider {
		width: 90%;
		height: 260px;
	}
	.video h2 {
		line-height: 1.3em;
	}
	.frm-contact {
		padding-top: 12px;
	}
	.frm-contact h4 {
		margin-bottom: 16px;
	}
	.frm-contact h4 {
		line-height: 1.3em;
		font-size: 24px;
	}
	.right-contact-row h1 {
		font-size: 32px;
		line-height: 1.3em;
		margin-bottom: 25px;
	}
	.row-container h3 {
		text-align: left;
	}
	#bottom-content div.bottom-row-left div.row-container h2 {
		margin: 18px auto;
		line-height: 1.2em;
	}
	#content-contact div div.right-contact-row div ul li p {
		padding-right: 16px;
	}
	#team-content h2 {
		font-size: 24px;
		line-height: 1.5em;
		width: 65%;
		margin: 0 auto;
	}
	#team-content {
		padding: 24px 10px;
	}
	#team-content hr {
		margin: 10px auto 20px auto;
	}
	.member-info {
		padding: 16px;
	}
	.member-info a {
		font-size: 12px;
	}
	.teamslideshow {
		min-height: 380px;
		padding-bottom: 0;
	}
	#banner-mid {
		padding-top: 0;
		height: auto;
		margin-top: 40px;
	}
	.video {
		background-color: #fff;
	}
	.video iframe {
		height: 190px;
	}
	div.chkbox-container input,
	input[type='checkbox'] + label span {
		width: 24px;
		height: 24px;
		padding: 0;
		margin: 0;
		margin-right: 5px;
	}
	div.chkbox-container .control-wrapper input:checked + label:after {
		left: 7px;
		width: 6px;
		height: 16px;
	}
	.chkbox-container p {
		width: 150px;
	}
	.chkbox-container .control-wrapper div:nth-child(1) {
		margin-right: 16px;
	}
	.checkbox-wrapper {
		width: 140px;
	}
	.chkbox-grid .chkbox input[type='checkbox']:checked + label:after {
		left: 7px;
		width: 6px;
		height: 16px;
	}
	.step,
	.step.active {
		width: 14px !important;
		height: 14px !important;
	}
	#content-contact > div > div.right-contact-row > div {
		width: 100%;
	}
	.row-container h3.prata2 {
		text-align: center;
	}
	#team-content h3 {
		line-height: 1.6em;
	}
	#headline-wrapper {
		width: 80%;
	}
	#headline-wrapper h3.prata {
		line-height: 1.6em;
		margin-bottom: 8px;
	}
	#quote-content-left > img {
		height: 170px;
	}
	#quote-content {
		background-size: auto 210px;
	}
	#hero-image {
		height: 100vh;
	}
	#testimonial-carousel .item .photo-section .quote {
		font-size: 80px;
		top: 12px;
	}
	.hello-week .week {
		font-size: 1em !important;
	}
}
